import { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from './modal';
import { Button, P, ErrorBanner } from '../../../lib/ui';
import toast from 'react-hot-toast'
import { useRouter } from 'next/router'

const DELETE_MUTATION = gql`
  mutation DeleteTemplate($id: ID!) {
    deleteTemplate(input: { id: $id })
  }
`;

function DeleteTemplateModal({ toggle, isOpen, item, route }) {
  const [formError, setFormError] = useState(null);
  const router = useRouter()
  const [deleteTemplate, { loading }] = useMutation(DELETE_MUTATION);
  const onSubmit = async (e) => {
    e.preventDefault();
    setFormError(null);
    try {
      await deleteTemplate({
        variables: { id: item.id },
        update: (cache) => {
          cache.evict({ id: cache.identify(item) });
          cache.gc();
        },
      });
      toast.success("Template deleted")
      if (route) {
        router.push('/templates')
      }
      toggle();
    } catch (error) {
      setFormError(error.message || 'An unknown error occurred on login.');
    }
  };
  useEffect(() => {
    if (isOpen) {
      setFormError(null);
    }
  }, [item, setFormError, isOpen]);

  return (
    <Modal toggle={toggle} isOpen={isOpen} size="sm:max-w-lg" closeButton>
      <div className="text-left">
        <div className="sm:flex sm:items-start mb-4">
          <h3
            className="text-3xl font-medium text-gray-900"
            id="modal-headline">
            Delete Template
          </h3>
        </div>
        <div>
          <P>Are you sure you want to delete template: {item?.name}</P>
        </div>
        {formError && <ErrorBanner title={formError} />}
        <div className="mt-6 mb-1 sm:flex">
          <Button
            tag="button"
            type="submit"
            onClick={onSubmit}
            disabled={loading}>
            {loading ? 'Deleting...' : 'Delete Template'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteTemplateModal;
