import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { useRouter } from 'next/router'

import Modal from './modal'
import { Button, ErrorBanner } from '../../../lib/ui'

type Inputs = {
  name: string
}

const GET_TEMPLATE_QUERY = gql`
  query GetTemplateForShare($id: ID) {
    getTemplate(id: $id) {
      name
      id
      updatedAt
      shareId
      rawTemplate
      css
    }
  }
`

const LIST_TEMPLATES_QUERY = gql`
  query ListTemplates($accountId: ID) {
    listTemplates(accountId: $accountId) {
      data {
        name
        id
        updatedAt
      }
    }
  }
`

const CREATE = gql`
  mutation CreateTemplate($name: String!, $css: String, $rawTemplate: String) {
    createTemplate(
      input: { name: $name, css: $css, rawTemplate: $rawTemplate }
    ) {
      id
      name
      description
      updatedAt
      css
      rawTemplate
    }
  }
`

const upgradeButton = (
  <p className='text-sm my-4'>
    <a
      href='/plans'
      target='_blank'
      className='bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded'
    >
      Upgrade
    </a>
  </p>
)

function DuplicateTemplateModal({ toggle, isOpen, item }) {
  const [formError, setFormError] = useState("")
  const [duplicating, setDuplicating] = useState(false)

  const { push } = useRouter()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<Inputs>({ mode: 'all' })

  useEffect(() => {
    if (item && isOpen) {
      reset({ name: `${item?.name} (copy)` })
    }
  }, [item, isOpen, reset])

  const [getTemplate, { loading, data }] = useLazyQuery(GET_TEMPLATE_QUERY, {
    ssr: false,
    variables: {
      id: item?.id,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
  })

  useEffect(() => {
    if (isOpen && item?.id && !loading) {
      getTemplate()
    }
  }, [isOpen, data, getTemplate, item, loading])

  const [createTemplate] = useMutation(CREATE)

  const onSubmit = async ({ name }, e) => {
    if (name?.trim()?.length === 0) {
      setFormError('Template name is required.')
    } else {
      setFormError("")
      try {
        setDuplicating(true)
        if (data) {
          const result: any = await createTemplate({
            variables: {
              name,
              rawTemplate: data?.getTemplate?.rawTemplate,
              css: data?.getTemplate?.css,
            },
            update: (cache, { data: { createTemplate } }) => {
              const result: any = cache.readQuery({
                query: LIST_TEMPLATES_QUERY,
              })
              cache.writeQuery({
                query: LIST_TEMPLATES_QUERY,
                data: {
                  listTemplates: {
                    ...result.listTemplates,
                    data: [...result.listTemplates.data, createTemplate],
                  },
                },
              })
            },
          })
          toggle()
          setDuplicating(false)
          push(`/templates/${result?.data?.createTemplate?.id}`)
        }
      } catch (error) {
        setFormError(error?.message || 'An unknown error occurred.')
        setDuplicating(false)
      }
    }
  }

  const showUpgrade = formError?.includes('please upgrade')

  return (
    <Modal toggle={toggle} isOpen={isOpen} size='sm:max-w-lg' closeButton>
      <form className='space-y-8' onSubmit={handleSubmit(onSubmit)}>
        <div className='text-left'>
          <div className='sm:flex sm:items-start mb-4'>
            <h3
              className='text-3xl font-medium text-gray-900'
              id='modal-headline'
            >
              Duplicate
            </h3>
          </div>
          <div>
            <div>
              <label
                htmlFor='name'
                className='block text-sm font-medium text-gray-700'
              >
                Template Name
              </label>
              <div className='mt-1'>
                <input
                  id='name'
                  name='name'
                  autoFocus
                  ref={register()}
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm'
                />
              </div>
              {/* {errors?.name && (
                <ErrorBanner title={'Template name is required.'} />
              )} */}
            </div>
          </div>
          {formError && (
            <ErrorBanner
              title={formError}
              children={showUpgrade ? upgradeButton : undefined}
            />
          )}
          <div className='mt-6 mb-1 sm:flex'>
            <Button
              tag='button'
              type='submit'
              disabled={isSubmitting || duplicating || showUpgrade}
            >
              {isSubmitting || duplicating
                ? 'Duplicating...'
                : 'Duplicate'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default DuplicateTemplateModal
