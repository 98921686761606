import { useForm } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from './modal';
import { Public, Button, P, Meta, ErrorBanner } from '../../../lib/ui';
import darkStarterImg from '../../../images/dark-starter-template.png';
import lightStarterImg from '../../../images/light-starter-template.png';
import { useRouter } from 'next/router';

const CREATE = gql`
  mutation CreateTemplate($name: String!, $templateId: String) {
    createTemplate(input: { name: $name, templateId: $templateId }) {
      id
      name
      description
      updatedAt
      css
      rawTemplate
    }
  }
`;

const LIST_TEMPLATES_QUERY = gql`
  query ListTemplates($accountId: ID) {
    listTemplates(accountId: $accountId) {
      data {
        name
        id
        updatedAt
      }
    }
  }
`;

const nameOpts = {
  required: 'A template name is required.',
};

type Inputs = {
  name: string;
  templateId: string;
};

const defaultValues = {
  name: '',
  templateId: 'blank',
};

const upgradeButton = (
  <p className="text-sm my-4">
    <a
      href="/plans"
      target="_blank"
      className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded">
      Upgrade
    </a>
  </p>
);

function NewTemplateModal({ toggle, isOpen }) {
  const focusInput = useRef(null);
  const { push } = useRouter();
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<Inputs>({ defaultValues });
  const [createTemplate] = useMutation(CREATE);
  const [formError, setFormError] = useState(null);
  const onSubmit = async ({ name, templateId }, e) => {
    e.preventDefault();
    setFormError(null);
    try {
      setLoading(true)
      const result: any = await createTemplate({
        variables: { name, templateId },
        update: (cache, { data: { createTemplate } }) => {
          const result: any = cache.readQuery({ query: LIST_TEMPLATES_QUERY });
          cache.writeQuery({
            query: LIST_TEMPLATES_QUERY,
            data: {
              listTemplates: {
                ...result?.listTemplates || [],
                data: [...result?.listTemplates?.data, createTemplate],
              },
            },
          });
        },
      });
      toggle();
      push(`/templates/${result?.data?.createTemplate?.id}`);
      setLoading(false)
      // TODO: route to editor
    } catch (error) {
      setFormError(error.message || 'An unknown error occurred.');
      setLoading(false)
    }
  };
  useEffect(() => {
    if (isOpen) {
      reset({ name: '', templateId: 'blank' });
      setFormError(null);
      if (focusInput.current) {
        focusInput.current.focus();
      }
    }
  }, [reset, isOpen, setFormError]);

  const showUpgrade = formError?.includes('please upgrade');

  return (
    <Modal toggle={toggle} isOpen={isOpen} size="sm:max-w-xl" closeButton>
      <form onSubmit={handleSubmit(onSubmit)} className="text-left">
        <div className="sm:flex sm:items-start mb-4">
          <h3
            className="text-3xl font-medium text-gray-900"
            id="modal-headline">
            New Template
          </h3>
        </div>
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700">
            Template Name
          </label>
          <div className="mt-1">
            <input
              id="name"
              name="name"
              autoFocus
              ref={(e) => {
                register(nameOpts)(e);
                focusInput.current = e;
              }}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
            />
          </div>
          {errors?.name && <ErrorBanner title={errors.name.message} />}
          <p className="block text-sm font-medium text-gray-700 mt-8">
            Starting Template
          </p>
          <div className="mt-4 grid grid-cols-1 gap-4 grid-cols-3">
            <div>
              <input
                type="radio"
                className="hidden"
                name="templateId"
                value="blank"
                id="blankTemplate"
                ref={register({ required: true })}
              />
              <label className="text-center" htmlFor="blankTemplate">
                <div className="rounded-md new-template-thumbnail h-32 block border-2 border-gray-200 bg-white shadow-sm"></div>
                <P>Blank</P>
              </label>
            </div>
            <div>
              <input
                type="radio"
                className="hidden"
                name="templateId"
                value="light"
                id="lightTemplate"
                defaultChecked
                ref={register({ required: true })}
              />
              <label className="text-center" htmlFor="lightTemplate">
                <div
                  className="rounded-md new-template-thumbnail h-32 bg-cover block border-2 border-gray-200 bg-white shadow-sm"
                  style={{ backgroundImage: `url(${lightStarterImg})` }}></div>
                <P>Simple (Light)</P>
              </label>
            </div>
            <div>
              <input
                type="radio"
                className="hidden"
                name="templateId"
                value="dark"
                id="darkTemplate"
                ref={register({ required: true })}
              />
              <label className="text-center" htmlFor="darkTemplate">
                <div
                  className="rounded-md new-template-thumbnail h-32 bg-cover block border-2 border-gray-200 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
                  style={{ backgroundImage: `url(${darkStarterImg})` }}></div>
                <P>Simple (Dark)</P>
              </label>
            </div>
          </div>
        </div>
        {formError && <ErrorBanner title={formError} children={showUpgrade ? upgradeButton : undefined} />}
        <div className="mt-6 mb-1 sm:flex">
          <Button tag="button" type="submit" disabled={(isSubmitting || isSubmitSuccessful || loading)}>
            {(isSubmitting || isSubmitSuccessful || loading) ? 'Adding...' : 'Add Template'}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default NewTemplateModal;
