import { useForm } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from './modal';
import { Public, Button, P, Meta, ErrorBanner } from '../../../lib/ui';

const UPDATE = gql`
  mutation UpdateTemplateName($name: String!, $id: ID!) {
    updateTemplate(input: { id: $id, name: $name }) {
      id
      name
      updatedAt
    }
  }
`;

const nameOpts = {
  required: 'A template name is required.',
};

type Inputs = {
  name: string;
};

const defaultValues = {
  name: '',
};

function EditTemplateModal({ toggle, isOpen, item }) {
  const focusInput = useRef(null);
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(null);
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm<Inputs>({ defaultValues });
  const [updateTemplate] = useMutation(UPDATE);
  const onSubmit = async ({ name }, e) => {
    e.preventDefault();
    setFormError(null);
    try {
      setLoading(true)
      await updateTemplate({ variables: { name, id: item.id } });
      toggle();
      setLoading(false)
    } catch (error) {
      setFormError(error.message || 'An unknown error occurred on login.');
      setLoading(false)
    }
  };
  useEffect(() => {
    if (isOpen) {
      reset({ name: item?.name || '' });
      setTimeout(() => {
        focusInput.current && focusInput.current.focus();
      }, 0);
    }
  }, [item, reset, isOpen]);
  return (
    <Modal toggle={toggle} isOpen={isOpen} size="sm:max-w-lg" closeButton>
      <form onSubmit={handleSubmit(onSubmit)} className="text-left">
        <div className="sm:flex sm:items-start mb-4">
          <h3
            className="text-3xl font-medium text-gray-900"
            id="modal-headline">
            Edit Template
          </h3>
        </div>
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700">
            Template Name
          </label>
          <div className="mt-1">
            <input
              name="name"
              id="name"
              ref={(e) => {
                register(nameOpts)(e);
                focusInput.current = e;
              }}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
            />
          </div>
          {errors?.name && <ErrorBanner title={errors.name.message} />}
        </div>
        {formError && <ErrorBanner title={formError} />}
        <div className="mt-6 mb-1 sm:flex">
          <Button tag="button" type="submit" disabled={(isSubmitting || loading)}>
            {(isSubmitting || loading) ? 'Saving...' : 'Save Template'}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default EditTemplateModal;
