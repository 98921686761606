import { useForm } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import Modal from './modal';
import {
  Public,
  Button,
  P,
  Meta,
  ErrorBanner,
  ButtonLink,
} from '../../../lib/ui';

const DELETE = gql`
  mutation DeleteShare($id: ID!) {
    deleteShare(input: { id: $id })
  }
`;

const CREATE = gql`
  mutation CreateShare($id: ID!) {
    createShare(input: { id: $id })
  }
`;

const GET_TEMPLATE_QUERY = gql`
  query GetTemplateForShare($id: ID) {
    getTemplate(id: $id) {
      name
      id
      updatedAt
      shareId
      rawTemplate
      css
    }
  }
`;

function ShareTemplateModal({ toggle, isOpen, id }) {
  const [isSubmitting, setFormSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);
  const [isShared, setShared] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [createShare] = useMutation(CREATE);
  const [deleteShare] = useMutation(DELETE);
  const [getTemplate, { loading, data }] = useLazyQuery(GET_TEMPLATE_QUERY, {
    ssr: false,
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
  });
  useEffect(() => {
    if (!isOpen) {
      setLoaded(false);
      setFormError(false);
      setCopied(false);
      return;
    }
    if (isOpen && id && data?.getTemplate) {
      setShared(!!data?.getTemplate?.shareId);
      // reset({ name: item?.name || '' });
    }
    // initial fetch
    if (isOpen && id && !isLoaded) {
      getTemplate();
      // set loaded after, or calls 2x
      setLoaded(true);
      // setShared(!!data?.getTemplate?.shareId);
      // reset({ name: item?.name || '' });
    }
  }, [id, isOpen, setShared, data, getTemplate, isLoaded, setLoaded]);
  const shareId = data?.getTemplate?.shareId;
  const shareUrl = shareId
    ? `${window.location.origin}/share/${shareId}`
    : `N/A`;
  const buttonDisabled = isSubmitting || loading;
  const copyTemplate = () => {
    setCopied(false);
    try {
      setTimeout(() => {
        navigator.clipboard.writeText(shareUrl);
        setCopied(true);
      }, 100);
    } catch (_error) {}
  };
  const toggleShare = async () => {
    if (buttonDisabled) {
      return;
    }
    setFormSubmitting(true);
    setFormError(null);
    setCopied(false);
    if (isShared) {
      try {
        await deleteShare({ variables: { id } });
        await getTemplate();
      } catch (error) {
        setFormError(error.message || 'An unknown error occurred.');
      }
    } else {
      try {
        await createShare({ variables: { id } });
        await getTemplate();
      } catch (error) {
        setFormError(error.message || 'An unknown error occurred.');
      }
    }
    setFormSubmitting(false);
  };
  return (
    <Modal toggle={toggle} isOpen={isOpen} size="sm:max-w-lg" closeButton>
      <div className="text-left">
        <div className="sm:flex sm:items-start mb-4">
          <h3
            className="text-3xl font-medium text-gray-900"
            id="modal-headline">
            Share Template
          </h3>
        </div>
        <div>
          <div className="mt-2 sm:flex sm:items-start">
            <div className="mt-5 sm:mt-0 flex-shrink-0 flex items-center">
              <button
                type="button"
                // disabled={buttonDisabled}
                className={`${isSubmitting ? 'animate-pulse' : ''} ${
                  isShared ? 'bg-blue' : 'bg-gray-200'
                } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue`}
                aria-pressed="false"
                aria-labelledby="share-label"
                onClick={toggleShare}>
                <span className="sr-only">Toggle Share</span>
                {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
                <span
                  aria-hidden="true"
                  className={`${
                    isShared ? 'translate-x-5' : 'translate-x-0'
                  } inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                />
              </button>
              <p className="ml-2 sm:ml-6 max-w-xl font-medium text-gray-700">
                Create a public shareable URL for this template.
              </p>
            </div>
          </div>
          {isShared && (
            <>
              <div className={`mt-4 ${isShared ? '' : 'opacity-50'}`}>
                <label
                  htmlFor="shareUrl"
                  className="block text-sm font-medium text-gray-700">
                  Read Only Preview URL
                </label>
                <div className="mt-1">
                  <input
                    name="shareUrl"
                    id="shareUrl"
                    disabled
                    value={shareUrl}
                    className="appearance-none block w-full px-3 py-2 bg-gray-200 border border-gray-400 rounded-md shadow-sm placeholder-gray-400 cursor-default disabled:opacity-70 sm:text-sm"
                  />
                </div>
                {/* {errors?.name && <ErrorBanner title={errors.name.message} />} */}
              </div>
              <div
                className={`${
                  isShared && !buttonDisabled ? '' : 'opacity-50'
                }`}>
                {formError && <ErrorBanner title={formError} />}
                <div className="mt-6 mb-1 sm:flex">
                  <Button
                    tag="button"
                    onClick={copyTemplate}
                    disabled={buttonDisabled || !isShared}>
                    {isCopied ? 'Copied!' : 'Copy To Clipboard'}
                  </Button>
                  <ButtonLink
                    outline
                    href={shareUrl}
                    target="_blank"
                    className="ml-2"
                    disabled={buttonDisabled || !isShared}>
                    Open
                  </ButtonLink>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ShareTemplateModal;
