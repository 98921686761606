import { Transition } from '@headlessui/react';
import { useEffect } from 'react';

function Modal({
  isOpen = false,
  toggle,
  children,
  size = 'sm:max-w-lg',
  closeButton = false,
  closeOnEsc = true,
}) {
  useEffect(() => {
    if (!closeOnEsc) {
      return;
    }
    const close = (e) => {
      if (e.keyCode === 27) {
        toggle();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);
  return (
    <Transition show={isOpen} className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center modal min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <Transition.Child
          // onClick={() => toggle(false)}
          className="fixed inset-0 transition-opacity"
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </Transition.Child>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true">
          ​
        </span>

        <Transition.Child
          enter="ease-out duration-200"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle ${size} w-full sm:p-6`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div>
            {children}
            {closeButton && (
              <div className="absolute top-0 right-0 block">
                <button
                  className="inline-flex p-2 text-gray-500 hover:text-gray-800 focus:outline-none"
                  onClick={toggle}>
                  <span className="sr-only">Dismiss</span>
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}

export default Modal;
